nav {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	position: fixed;
	background-color: var(--main-bg-color);
	
	top: 0;
	transition: all 2s ease;
}
nav.up {
	transform: translateY(-300px);

}
.img-holder {
	margin-left: 2rem;
	width: 10rem;
	background-color: var(--main-bg-color);
	border: .5rem solid var(--main-bg-color);
	border-radius: 50%;
}
.img-holder img {
	width: 100%;
}
.logo-holder{
text-align: left;

}
.logo-holder img{
width:12rem;
}
.nav-links {
	margin-right: 2rem;
}
.nav-links a {
	font-family: var(--main-btn-font);
	font-size: small;
	text-decoration: none;
	margin-right: 2rem;
	color: var(--main-heading-color);
}

@media only screen and (max-width: 500px) {
	.img-holder {
		margin-left: 1rem;
		width: 3rem;
		
	}
	.nav-links a {
		margin-right: .8rem;
		font-size: xx-small;
	}
	.nav-links {
		margin-right: .5rem;
		
	}

}
.nav-links a span {
	font-size: large;
}
@media only screen and (max-width: 332px) {
	nav{
		justify-content: start;
	}
	.nav-links a {
		margin-right: .7rem;
		font-size: .6rem;
	}
	.nav-links {
		margin-right: 0;
	}

}
.nav-links a span {
	font-size: large;
}