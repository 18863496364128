.player-wrapper {
    width:100%;
    position: relative;
    padding-top:56%;  /*56.25%; Player ratio: 100 / (1280 / 720)
     */
     margin-left: auto;
     margin-right: auto;
  }

   .vid-style{
width:50%;
margin-left: auto;
margin-right: auto;
text-align: center;
   }
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
 
  .videohead{
    margin-bottom: 6rem;
    margin-top:10em ;
    text-align: center;
    color:var(--main-heading-color);
  }
  .videotitle{
    font-family: var(--main-heading-font);
    font-size: 3em;
    margin-top: 7rem;
    font-weight: 300;
  }
  .belowvideo{
margin: 2rem 1rem 5rem 1rem;
text-align: center;
  }
  .belowvideocontent{
    text-align: center;
 
  }
  .il{
    margin-left: auto;
    margin-right: auto;
  }
  .flexi{
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    display:flex;
    justify-content: space-around;
    margin-top: 4rem;
    margin-bottom:4rem;
  }
  .lboy{
    width:4rem;
  }
  .lboy img {
    width:100%;
  }
  .vidp{
    width:35%;
   
  }
  .vidp p{
    width: 100%;
  }
  @media only screen and (max-width: 700px) {
    .vidp{
      width:45%;
      font-size: smaller;
    }
    .vid-style{
      width:70%;
    }
    .lboy{
      width:3rem;
    }
    .videotitle{
      font-size: 2em;
    }
    .thu{
      font-size: small;
    }
    .available p{
      font-size: 1.5rem;
    }
    .il{
      width:70%;
    }
  }