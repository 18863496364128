.modal {
	z-index: 100000001;
	position: fixed;
	top: 10vh;
	left: 10%;
	width: 80%;
	background:var(--main-heading2-color);
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
	border-radius: 8px;
}
@media only screen and (max-width: 600px) {
	.modal{
	top:-2vh;
	background-color: transparent;
	box-shadow: unset;
	}
	.modal__footer{
		display:none;
	}
}
.swal2-container {
	z-index: 20000000000;
  };
.modal__header {
	width: 100%;
	padding: 1rem 0.5rem;
	background: #6e6c00;
	color: white;
}

.modal__header h2 {
	margin: 0.5rem;
}

.modal__content {
	padding: 1rem 0.5rem;
}

.modal__footer {
	padding: .2rem 0.5rem;
	background-color: var(--main-heading-color);
}

@media (min-width: 768px) {
	.modal {
		left: calc(50% - 20rem);
		width: 40rem;
		top: 20vh;
	}
}

.modal-enter {
	transform: translateY(-10rem);
	opacity: 0;
}

.modal-enter-active {
	transform: translateY(0);
	opacity: 1;
	transition: all 200ms;
}

.modal-exit {
	transform: translateY(0);
	opacity: 1;
}

.modal-exit-active {
	transform: translateY(-10rem);
	opacity: 0;
	transition: all 200ms;
}
