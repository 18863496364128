.design1{
    display:flex;
    flex-direction: column;
}
.holder-1,.holder-2{
    margin-top: 3em;
    display:flex;
   flex-direction: row;
   justify-content: space-around;
}
.holder-2{
    margin-bottom: 5em;
    margin-top: 5em;
}
.iloader{
    width:50%;
}
.what-do-stuff{
    margin-top: 3em;
    margin-bottom: 2em;
    font-size: 2.5rem; 
    font-weight: 300;
    text-align: center;
    font-family: var(--main-text-font);
    color: var(--main-heading-color);
}

/* .sortablelist{
display:grid;
grid-template-columns:1f 1fr 1fr !important;
gap:10px;
width:100%;
margin-top:50px;
} */
@media only screen and (max-width: 700px) {
    .holder-1{
flex-direction: column;
align-items: center;
    }
    .design1{
        align-items: center;
    }
    .holder-2{
        flex-direction: column-reverse;
    } 
    .what-do-stuff{
        font-size: 1.5rem;
    }
}
.desa{
    text-align: center;
    }
    .desb{
    text-align: center;
    }
.des-img-stuff{
margin-right: 14em;
}
.des-img-stuff1{
   margin-left: 14em; 
}
@media only screen and (max-width: 600px) {
    .des-img-stuff > #boy,.des-img-stuff1 > #boy2{
        width: 50%;
    }
}