:root {
	--main-bg-color: #c0ffd3;
	--main-heading-color: #237873;
	--main-heading2-color: #52de97;
	--main-contrast-color: #ffba5a;

	--main-heading-font: 'Great Vibes', cursive;
	--main-btn-font: 'Montserrat', sans-serif;
	--main-text-font: 'Open Sans', sans-serif;

	--default-text-color: #545454;

	--rotateAmount: 360deg;
}
img.loading_arrow {
	-webkit-animation: spin_delay 1.5s linear infinite;
	animation: spin_delay 1.5s linear infinite;
}

@keyframes spin_delay {
	0% {
		-webkit-transform-origin: center;
		-ms-transform-origin: center;
		transform-origin: center;
		-webkit-transform: rotate3d(0, 1, 0, 0deg);
		-ms-transform: rotate3d(0, 1, 0, 0deg);
		transform: rotate3d(0, 1, 0, 0deg);
	}
	25% {
		-webkit-transform: rotate3d(0, 1, 0, 90deg);
		-ms-transform: rotate3d(0, 1, 0, 90deg);
		transform: rotate3d(0, 1, 0, 90deg);
	}

	50% {
		-webkit-transform: rotate3d(0, 1, 0, 180deg);
		-ms-transform: rotate3d(0, 1, 0, 180deg);
		transform: rotate3d(0, 1, 0, 180deg);
	}
	75% {
		-webkit-transform: rotate3d(0, 1, 0, 270deg);
		-ms-transform: rotate3d(0, 1, 0, 270deg);
		transform: rotate3d(0, 1, 0, 270deg);
	}
	100% {
		-webkit-transform: rotate3d(0, 1, 0, 360deg);
		-ms-transform: rotate3d(0, 1, 0, 360deg);
		transform: rotate3d(0, 1, 0, 360deg);
	}
}
body {
	margin: 0;
	background-image: linear-gradient(to bottom, var(--main-bg-color), #fff);
	font-family: 'Open Sans', sans-serif;
}
.coverall{
	width:100vw;
	height:100vh;
	background-image: linear-gradient(to bottom, var(--main-bg-color), #fff);
	z-index: 50000;
	display:grid;
	grid-template-columns: .5fr 1fr .5fr;
	grid-template-rows: .5fr 1fr .5fr;
}
.covered{
	grid-column: 1 / span1 ;
	grid-row: 1/ span1;
	width:50%;
	height:50%;
	margin:auto ;	
	animation-name: scale_delay;
	animation-duration: 1s; 
	animation-timing-function: ease-out; 
	animation-delay: 1.5s;
	animation-direction: normal;
	animation-iteration-count: 1;
	animation-fill-mode: backwards;
	animation-play-state: running; 
}
@keyframes scale_delay{
	0%{

-webkit-transform: scale(1.0);
transform:scale(1);
	}
	100%{

-webkit-transform: scale(0);
transform:scale(0);
	}
}
.App{
	animation-name: opacity_delay;
	animation-duration: 1.5s; 
	animation-timing-function: ease-out; 
	animation-delay: 0s;
	animation-direction: normal;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
	animation-play-state: running; 
}
@keyframes opacity_delay{
	0%{

opacity: 0;
	}
	100%{

opacity: 1;
	}
}
