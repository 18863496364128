.form-control {
    margin: 1rem 0;
  }
  
  .form-control label,
  .form-control input,
  .form-control textarea {
    display: block;
  }
  
  .form-control label {
    font-weight: bold;
    margin-bottom: 0.5rem;
    font-family: var(--main-btn-font);
    color:var(--main-heading-color) ;
  }
  
  .form-control input,
  .form-control textarea {
    width: 100%;
    font-family: var(--main-btn-font);
    border: 1px solid #ccc;
    background: #f8f8f8;
    padding: 0.15rem 0.rem;
  }
  
  .form-control input:focus,
  .form-control textarea:focus {
    outline: none;
    background: #ebebeb;
    border-color: #510077;
  }
  
  .form-control--invalid label,
  .form-control--invalid p {
    color: red;
  }
  
  .form-control--invalid input,
  .form-control--invalid textarea {
    border-color: red;
    background: #ffd1d1;
  }
  @media only screen and (max-width: 600px) {
    .form-control label{
      display:none;
    }
    .form-control {
      margin: 0 .2em;
    }
  }