.slideshow-headline{
    display:flex;
    justify-content: space-evenly;
    flex-direction: row;
    margin-top: 4em;
 
}
@media only screen and (max-width: 500px) {
    .slideshow-headline{
        flex-direction: column;
        align-items: center;
    }
}

.s-h-p1, .s-h-p2{
width:100%;
z-index:1000000000;
margin-bottom:0;
font-family: var(--main-btn-font);
color: var(--main-heading-color);
color:var(--default-text-color);
font-size: small;
}
.s-h-p2{
font-size: x-small;
margin-top: 13px;
}